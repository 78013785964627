body {
  --primary: #000000;
  --secondary: #539fe5;

  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Specific */

.surveyCategories {
  list-style-type: none;
}

.categoryName {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.dash {
  width: 75%;
  border-top: 4px dotted #d7d7d7;
  margin-top: 10px;
  transform: rotate(-0.07deg);
}

/* Survey-core Custom Changes */

body #creatorElement {
  --primary-color: #7ff07f;
  --secondary-color: #1ab394;
  --primary-text-color: #4a4a4a;
  --secondary-text-color: #a7a7a7;
  --inverted-text-color: #ffffff;
  --primary-hover-color: #6fe06f;
  --selection-border-color: #1ab394;
  --primary-icon-color: #3d4d5d;
  --primary-bg-color: #f8f8f8;
  --secondary-bg-color: #f4f4f4;
  --primary-border-color: #e7eaec;
  --secondary-border-color: #ddd;
  --error-color: #ed5565;
}

.sd-btn.sd-btn {
  background: var(--color-background-button-normal-default-syw2p1, #ffffff);
  color: var(--color-text-button-normal-default-u8647m, #0972d3);
  border-color: var(--color-border-button-normal-default-djv8f5, #0972d3);
  position: relative;
  text-decoration: none;
  border-collapse: separate;
  border-spacing: 0;
  caption-side: top;
  cursor: auto;
  direction: ltr;
  empty-cells: show;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
  hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: left;
  text-align-last: auto;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  widows: 2;
  word-spacing: normal;
  box-sizing: border-box;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  font-weight: 400;
  font-family: var(
    --font-family-base-qnistn,
    "Open Sans",
    "Helvetica Neue",
    Roboto,
    Arial,
    sans-serif
  );
  min-width: 0;
  -ms-word-break: break-all;
  word-break: break-word;
  font-weight: var(--font-button-weight-7rj5fx, 800);
  -webkit-font-smoothing: var(--font-smoothing-webkit-vu3hx4, antialiased);
  -moz-osx-font-smoothing: var(--font-smoothing-moz-osx-nkh1jb, grayscale);
  letter-spacing: var(--font-button-letter-spacing-8y5poz, 0.005em);
  border-radius: var(--border-radius-button-axewzv, 20px);
  border: var(--border-field-width-idlekx, 2px) solid;
  padding: var(--space-scaled-xxs-95dhkm, 4px) var(--space-l-4vl6xu, 20px) !important;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
}

.sd-btn.sd-btn:not(#\9):hover {
  background: var(--color-background-button-normal-hover-c3qbgm, #f2f8fd);
  color: var(--color-text-button-normal-hover-kxmvcc, #033160);
  border-color: var(--color-border-button-normal-hover-e1d0kh, #033160);
  text-decoration: none;
  box-shadow: none;
}
.sd-btn.sd-btn:not(#\9):active {
  background: var(--color-background-button-normal-active-9lyks0, #d3e7f9);
  color: var(--color-text-button-normal-active-6obrzh, #033160);
  border-color: var(--color-border-button-normal-active-zbmaft, #033160);
}

.sd-body.sd-body--static.sd-body--static {
  max-width: calc(98 * var(--base-unit, 8px));
  margin-left: auto;
  margin-right: auto;
}

.login-component {
  display: flex;
  flex-direction: row nowrap;
  justify-content: center;
}

.login-container {
  justify-content: center;
  align-content: center;
  text-align: center !important;
  padding: 4em;
  margin: 4em;
  width: 400px;
}

.results {
  display: block;
  flex-direction: row wrap;
  justify-content: center;
  align-content: center;
  text-align: center;
  padding: 1em;
  margin: 1em auto;
  width: 70%;
}

.overallGrade {
  font-size: 3.5em;
  font-weight: 600;
}

.overallLevel {
  font-size: 2em;
  padding-right: 10px;
}

.resultsHeader {
}

.userResults {
  display: flex;
  flex-direction: row nowrap;
  justify-content: center;
  vertical-align: middle;
  padding: 16px;
}

/* RESULT CARDS */
[class^="awsui_card-inner"]
{
  margin-left: 0 !important;
}
[class^="awsui_list_"] {
  padding-left: 0 !important;
}


/* TIMELINE */

.progress-bar {
  margin-bottom: 3em;
  display: table;
  width: 100%;
  table-layout: fixed;
  position: relative;
}
.progress-bar .step {
  display: table-cell;
  text-align: center;
  vertical-align: top;
  overflow: visible;
  position: relative;
  font-size: 14px;
  color: #000;
  font-weight: bold;
}
.progress-bar .step:not(:last-child):before {
  content: '';
  display: block;
  position: absolute;
  left: 50%;
  top: 37px;
  background-color: #e1e1e1;
  height: 6px;
  width: 100%;
}
.progress-bar .step .node {
  display: inline-block;
  border: 6px solid #e1e1e1;
  background-color: #fff;
  border-radius: 18px;
  height: 18px;
  width: 18px;
  position: absolute;
  top: 25px;
  left: 50%;
  margin-left: -18px;
}
.progress-bar .step.complete:before {
  background-color: #0972d3;
}
.progress-bar .step.complete .node {
  border-color: #0972d3;
  background-color: #0972d3;
}
/* .progress-bar .step.complete .node:before {
  font-family: FontAwesome;
  content: "\f00c";
} */

.progress-bar .step.complete .completedCheck, 
.progress-bar .step.step.in-progress .completedCheck {
  bottom: 2px;
}

.progress-bar .step.step.in-progress:before {
  background: #0972d3;
  background: -moz-linear-gradient(left, #0972d3 0%, #fff 100%);
  background: -webkit-linear-gradient(left, #0972d3 0%, #fff 100%);
  background: linear-gradient(to right, #0972d3 0%, #fff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0972d3", endColorstr="#fff",GradientType=1);
}
/* .progress-bar .step.step.in-progress:before {
  background: #0972d3;
  background: -moz-linear-gradient(left, #0972d3 0%, #fff 100%);
  background: -webkit-linear-gradient(left, #0972d3 0%, #fff 100%);
  background: linear-gradient(to right, #0972d3 0%, #fff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0972d3", endColorstr="#fff",GradientType=1);
} */
.progress-bar .step.in-progress .node {
  background: #0972d3;
  border-color: #0972d3;
}
